import * as React from "react";
import { Link, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { convertDate, truncateString } from "../utils/utils";

const NewsListItem = (props: any) => {
  var news = props.news;
  return (
    <div className="col-md-4 align-self-stretch d-flex">
      <div className="news-card align-self-stretch">
        <div className="image-area">
          <img src={news?.frontmatter?.imageUrl} alt="" />
        </div>
        <div className="news-info">
          <Link to={`/news/${news?.frontmatter?.slug}`}>
            <h4>{news?.frontmatter?.title}</h4>
          </Link>
          <div className="post-intro">
            <p>{truncateString(news?.frontmatter?.summary, 200)}</p>
          </div>
          <p className="post-info">
            <span id="posted-by">{news?.frontmatter?.postedBy}</span> on{" "}
            <span id="posted-date">{convertDate(news?.frontmatter?.date)}</span>
            <span id="category">{news?.frontmatter?.category}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewsListItem;
